import { FlowDropdownInput } from "#batteries-included-components/Dropdowns/FlowDropdownInput";
import { AssetGroupDropdownInput } from "#src/batteries-included-components/Dropdowns/AssetGroupDropdownInput";
import { DeviceDropdownInput } from "#src/batteries-included-components/Dropdowns/DeviceDropdownInput";
import { EquipmentDropdownInput } from "#src/batteries-included-components/Dropdowns/EquipmentDropdownInput";
import { FacilityDropdownInput } from "#src/batteries-included-components/Dropdowns/FacilityDropdownInput";
import { FormSchemaDropdownInput } from "#src/batteries-included-components/Dropdowns/FormSchemaDropdownInput";
import {
  DateSelectorInput,
  DropdownInput,
  GeoPointInput,
  RadioInput,
  TextInput,
} from "@validereinc/common-components";
import { AssetType, CustomAttributeType, Resources } from "@validereinc/domain";
import startCase from "lodash/startCase";
import React, { useMemo } from "react";

// REVIEW: with the Node BE. number array should be permanently removed and geo point support should be added
const BACKEND_UNSUPPORTED_FILTER_TYPES = ["number-array", "geo_point"];

// IMPROVE: this component needs a rewrite for maintainability
const CustomAttributeField = ({
  attribute,
  isDisabled,
  subtype,
  isOptionalTextShown,
  isFilterInput,
  name,
}: {
  attribute: CustomAttributeType;
  subtype?: string;
  isDisabled?: boolean;
  isOptionalTextShown?: boolean;
  isFilterInput?: boolean;
  name?: string;
}) => {
  const {
    data_type,
    is_required,
    field_name,
    display_name,
    entity_subtypes,
    unit,
    description,
    pick_list_values,
    lookup_entity_type,
  } = attribute;

  // IMPROVE: this should be typed but currently hard to do since we don't have
  // a well made shared type that all inputs inherit
  const commonProps = {
    name: name || `custom_attributes.${field_name}`,
    label: display_name,
    isRequired: isFilterInput ? false : is_required,
    isDisabled,
    unit,
    description,
    isOptionalTextShown: isFilterInput ? false : isOptionalTextShown,
  };

  const pickListValues = useMemo(() => {
    if (pick_list_values?.length && typeof pick_list_values?.[0] === "string") {
      return pick_list_values.map((value) => ({
        id: value,
        name: startCase(value),
      }));
    }

    return pick_list_values;
  }, [pick_list_values]);

  // IMPROVE: unsupported flters should show anyway, with a UI treatment indicating why
  if (isFilterInput && BACKEND_UNSUPPORTED_FILTER_TYPES.includes(data_type)) {
    return null;
  }

  // IMPROVE: subtype filters should show anyway with a UI treatment indicating why
  if (
    subtype &&
    entity_subtypes?.length &&
    !entity_subtypes.includes(subtype)
  ) {
    return null;
  }

  switch (data_type) {
    case "number-array":
    case "string":
      return <TextInput {...commonProps} />;
    case "number":
      return (
        <TextInput
          {...commonProps}
          type="number"
        />
      );
    case "integer":
      return (
        <TextInput
          {...commonProps}
          type="number"
          step={1}
        />
      );
    case "date":
      return (
        <DateSelectorInput
          {...commonProps}
          isFluid
        />
      );
    case "date-time":
      return (
        <DateSelectorInput
          {...commonProps}
          variant="time"
          isFluid
        />
      );
    case "date-time-range":
      return (
        <DateSelectorInput
          {...commonProps}
          isRange
          variant="time"
          isFluid
        />
      );
    case "lookup":
      switch (lookup_entity_type) {
        case AssetType.FACILITY:
          return (
            <FacilityDropdownInput
              {...commonProps}
              isOptionalTextShown={!isFilterInput}
              isMulti={!!isFilterInput}
            />
          );
        case AssetType.EQUIPMENT:
          return (
            <EquipmentDropdownInput
              {...commonProps}
              isOptionalTextShown={!isFilterInput}
              isMulti={!!isFilterInput}
            />
          );
        case AssetType.FLOW:
          return (
            <FlowDropdownInput
              {...commonProps}
              isOptionalTextShown={!isFilterInput}
              isMulti={!!isFilterInput}
            />
          );
        case AssetType.DEVICE:
          return (
            <DeviceDropdownInput
              {...commonProps}
              isOptionalTextShown={!isFilterInput}
              isMulti={!!isFilterInput}
            />
          );
        case AssetType.ASSET_GROUP:
          return (
            <AssetGroupDropdownInput
              {...commonProps}
              isOptionalTextShown={!isFilterInput}
              isMulti={!!isFilterInput}
            />
          );
        case Resources.FORM_SCHEMA:
          return (
            <FormSchemaDropdownInput
              {...commonProps}
              isOptionalTextShown={!isFilterInput}
              isMulti={!!isFilterInput}
            />
          );
        default:
          return null;
      }
    case "pick-list":
    case "multi-pick-list":
      return (
        <DropdownInput
          {...commonProps}
          options={pickListValues}
          labelKey="name"
          valueKey="id"
          // REVIEW: this causes picklists to show as multi-picklists but cannot be avoided for now till the DropdownInput is refactored to return a consistent input shape
          isMulti={isFilterInput || data_type === "multi-pick-list"}
          isFluid
        />
      );
    case "geo_point":
      return (
        <GeoPointInput
          {...commonProps}
          isFluid
          shouldWrap
        />
      );
    case "boolean":
      return (
        <RadioInput
          {...commonProps}
          labelKey="label"
          valueKey="value"
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
      );
    default:
      return null;
  }
};

export default CustomAttributeField;
